import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';

import {SaveFilters} from './actions';


@State<any>({
  name: 'filter'
})
@Injectable()
export class FilterState {

  @Action(SaveFilters)
  SaveFilters({patchState, getState}: StateContext<any>, {id, value}: SaveFilters) {
    patchState({[id]: value});
  }
}
