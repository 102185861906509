import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { SavePagination } from './actions';


@State<any>({
  name: 'paginationState'
})
@Injectable()
export class PaginationState {

  @Action(SavePagination)
  SavePagination({ patchState, getState, setState }: StateContext<any>, { id, value }: SavePagination) {
    patchState({ [id]: value });
  }
}
